import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCard, MDBCardBody } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternating from '../../components/columnAlternating'
import Cta from '../../components/cta'
import ColumnAlternatingButton from '../../components/columnAlternatingButton'
import ColumnAlternatingWistia from '../../components/columnAlternatingWistia'
import Card from '../../components/card'
import RecommendedContent from "../../components/recommendedContent"
import Img from 'gatsby-image'
import {targetingEnabled} from '../../utils/helpers.js'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const famplats = data.allMarkdownRemark.edges
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        <RecommendedContent />

        <section className={`${targetingEnabled() ? 'bg-white' : 'bg-white-grey-gradient'}`}>
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              {post.frontmatter.podcast.link.indexOf("wistia") > 0 ? 
                <ColumnAlternatingWistia
                  textCol={'5'}
                  imageCol={'7'}
                  placement={post.frontmatter.podcast.placement}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  height={post.frontmatter.podcast.height}
                  ctalink={post.frontmatter.podcast.ctalink}
                  ctatext={post.frontmatter.podcast.ctatext}
                  icon={post.frontmatter.podcast.icon}
                />
              :
                <ColumnAlternatingButton
                  textCol={'5'}
                  imageCol={'7'}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  imageUrl={post.frontmatter.podcast.image.childImageSharp.fluid}
                  imageAltText={post.frontmatter.podcast.alttext}
                  imageClass="rounded drop-shadow"
                  placement={post.frontmatter.podcast.placement}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  linktext={post.frontmatter.podcast.linktext}
                  ctalink={post.frontmatter.podcast.ctalink}
                  ctatext={post.frontmatter.podcast.ctatext}
                  icon={post.frontmatter.podcast.icon}
                />
              }
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        {post.frontmatter.requirement ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">{post.frontmatter.requirement.title}</h2>
                  <p className="pb-5 font-w-400 text-medium">{post.frontmatter.requirement.description}</p>

                  {post.frontmatter.requirement.section && (
                    <>
                      {post.frontmatter.requirement.section.map(
                        (sections, index) => {
                          return (
                            <ColumnAlternating
                              key={index}
                              textCol={'7'}
                              imageCol={'5'}
                              title={sections.title}
                              subtitle={sections.subtitle}
                              subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                              description={sections.description}
                              imageUrl={sections.image.childImageSharp.fluid}
                              imageAltText={sections.alttext}
                              placement={sections.placement}
                              titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                              link={sections.btnlink}
                              colour={sections.btncolour}
                            />
                          )
                        }
                      )}
                    </>
                  )}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.simplify.title}
                ctadescription={post.frontmatter.simplify.description}
                ctalink={post.frontmatter.simplify.link}
                ctalinktext={post.frontmatter.simplify.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {famplats &&
          <>
          {post.frontmatter.platform.section.map((section, index) => {
            let gen = index === 0 ? 4 : 3
            return (
            <section className={index === 0 ? "bg-white" : "bg-white-grey-gradient"} key={index}>
              <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
                <MDBContainer>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                    {section.title}
                  </h2>
                  {section.subtitle && 
                    <div className="mb-5 font-w-400 text-medium">
                      {section.subtitle}
                    </div>
                  }

                  {famplats.sort((a, b) => a.node.frontmatter.sequence == b.node.frontmatter.sequence ? 0 : 
                    a.node.frontmatter.sequence < b.node.frontmatter.sequence ? -1 : 1).map(({ node }, subindex) => (
                      <MDBRow className="flex-row" key={subindex}>
                        {node.frontmatter.platformsbyfamily && (
                          <>
                            {node.frontmatter.platformsbyfamily.sort((a, b) => a.frontmatter.sort == b.frontmatter.sort ? 0 : 
                              a.frontmatter.sort < b.frontmatter.sort ? -1 : 1).map((platform, subindex) => {
                                return (
                                  <Fragment key={subindex}>
                                    {platform.frontmatter.section.filter((platform) => (platform.eol !== true && platform.generation === gen)).map((details, subindex) => {
                                        return (
                                          <MDBCol md="4" className="py-3 d-flex" key={subindex}>
                                            <MDBCard className="flex-fill">
                                              <div style={{ minHeight: '200px', maxHeight: '220px' }}>
                                                <Img 
                                                  fluid={details.image.childImageSharp.fluid}
                                                  className="m-3"
                                                  alt={details.alttext}
                                                />
                                              </div>
                                              <MDBCardBody>
                                                <h3 className="card-title text-center title-extra-small font-w-700">
                                                  {details.title}
                                                </h3>
                                                <div className="text-center">
                                                  {details.feature ? (
                                                    <Link
                                                      className="effect text-medium font-w-400"
                                                      to={platform.fields.slug ? platform.fields.slug : details.link}
                                                    >                                                    
                                                        {platform.fields.slug
                                                          ? 'View platform page'
                                                          : 'View datasheet'}
                                                    </Link>
                                                  ) : (
                                                    <a 
                                                      href={details.document.publicURL}
                                                      target="_blank"
                                                      rel="noopener"
                                                      className="effect text-medium font-w-400 effect"
                                                    >
                                                        View datasheet
                                                    </a>
                                                  )}
                                                </div>
                                              </MDBCardBody>
                                            </MDBCard>
                                          </MDBCol>
                                        )
                                      }
                                    )}
                                  </Fragment>
                                )
                              })}
                          </>
                        )}
                      </MDBRow>
                    ))}
                </MDBContainer>
              </MDBAnimation>
            </section>
          )})}
          </>
        }

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => {
                          return (
                            <Fragment key={index}>
                              <Card
                                collg={(post.frontmatter.helpfulresources.helpfulres.length % 3 === 0) ? "4" : (post.frontmatter.helpfulresources.helpfulres.length % 4 === 0) ? "3" : "4" }
                                colmd="6"
                                height="9.5rem"
                                title={helpfulres.title}
                                subtitle={helpfulres.subtitle}
                                description={helpfulres.description}
                                imageUrl={helpfulres.image.childImageSharp.fluid}
                                imageAltText={helpfulres.alttext}
                                placement={helpfulres.placement}
                                link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                                titleclass="title-small"
                                descriptionClass="text-card-small"
                              />
                            </Fragment>
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "liquid-cooling" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        openingpara
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 720, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
        requirement {
          title
          description
          section {
            title          
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            placement
            alttext
            btnlink
            btncolour
        }
      }
        podcast {
          title
          subtitle
          description
          placement
          linktext
          link
          ctatext
          ctalink
          icon
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        simplify {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 615, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
        platform {
          section {
            title
            subtitle
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
    allMarkdownRemark(
      filter: {frontmatter: {familyname: {eq: "Liquid and Immersion Cooling-Based Servers"}}},
      sort: {fields: frontmatter___platformsbyfamily___frontmatter___sort, order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            familyname
            sequence
            platformsbyfamily {
              frontmatter {
                template
                sort
                title
                subtitle
                foreimage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                section {
                  title
                  eol
                  link
                  linktext
                  document {
                    publicURL
                  }
                  generation
                  alttext
                  image {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  feature {
                    title
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import Showdown from 'showdown'

export default class ColumnAlternatingWistia extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wistiaUrl: this.props.link,
    }
  }

  componentDidMount() {
    this.setState({
      content: this.state.content,
    })
    this.handleScriptLoad()
  }
  
  handleScriptLoad = () => {
    let allsuspects=document.getElementsByTagName("script");
    for (let i=allsuspects.length; i>=0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("id")!==null 
      && allsuspects[i].getAttribute("id").indexOf("uei-wistia") !== -1 ){
             allsuspects[i].parentNode.removeChild(allsuspects[i])
        }    
    }

    
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = this.props.link + ".jsonp";
    script.setAttribute("id", "uei-wistia-media");
    script.async = true; 
    document.getElementsByTagName('head').item(0).appendChild(script);

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//fast.wistia.com/assets/external/E-v1.js";
    script.setAttribute("id", "uei-wistia-assets");
    script.async = true; 
    document.getElementsByTagName('head').item(0).appendChild(script);
  }
    
  render() {
    const converter = new Showdown.Converter()
    const wistiaId = this.props.link.substring((this.props.link.indexOf("/medias/")+8))

    return (
      <>
        <MDBRow className={`about-itemx align-items-center pb-3 ${this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
          <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="px-5" >
            {wistiaId && (
              <span className={`wistia_embed wistia_async_${wistiaId} popover=true popoverAnimateThumbnail=true`} style={{display: "inline-block", width: "100%", height: this.props.height ? this.props.height : "300px"}}>&nbsp;</span>
            )}
          </MDBCol>
          <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="mt-4 mt-lg-0 mb-4 mb-lg-0" >
            <h3 className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
              {this.props.title}
            </h3>
            {(this.props.subtitle != null && this.props.subtitle != "") && (
              <h4 className={ this.props.subtitleClass != null ? this.props.subtitleClass : `font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium ${this.props.subtitlecolour}` } >
                {this.props.subtitle}
              </h4>
            )}
            <div
              className="mt-3 mb-3 font-w-400 text-medium divlink"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.description) }}
            />
          </MDBCol>
        </MDBRow>

        {(this.props.ctalink && this.props.ctatext !== " ") &&
          <MDBRow>
            <MDBCol>
              {(this.props.ctalink.substring(0, 4) != 'http' && !this.props.ctalink.includes('.pdf')) ? (
                <Link to={this.props.ctalink} className="nav-link btn btn-mdb-color btn-sm-block mt-4">
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.ctatext}
                </Link>
              ) : (
                <a href={this.props.ctalink} className="nav-link btn btn-mdb-color btn-sm-block mt-4" target="_blank" rel="noopener">
                  {this.props.icon == 'right' ? (
                    <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                  )}
                  {this.props.ctatext}
                </a>
              )}
            </MDBCol>
          </MDBRow>
        }
      </>
    )
  }
}